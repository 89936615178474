import React from 'react'
import AppRouter from './router'

const App = () => {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
