import './footer.scss';

import React from 'react';

import { Link } from 'react-router-dom';

import { AppLogo } from '../';
import instagramImage from '../../assets/instagram.png';
import linkedinImage from '../../assets/linkedin.png';
import twitterImage from '../../assets/twitter.png';

const AppFooter = () => {
  return (
    <footer className="app-footer">
      <div className="content-section">
        <div style={{ textAlign: 'right'}}>
        <button 
        id="zf-widget" 
        data-zf-d_id="R3BwFn1fai7ni78a4"
        data-zf-id="sgGyRJWUnGO9eqyabS8U"
        data-zf-type="popup"
        data-popup-size="medium"
        className='user-input-form-trigger__button'
      > 
        Climate Action Hero
      </button>
        </div>
        <div className="top-row">
          <div className="l-side">
            <AppLogo width={90} />
            <h3 className="mail-info">
              supclubindia@gmail.com
            </h3>
          </div>
          <div className="r-side">
            <div className="social-link">
              <a href="https://www.linkedin.com/company/sup-sustainable-upgrade-program/" target="_blank" rel="noreferrer">
                <img src={linkedinImage} alt="Linkedin" />
              </a>
            </div>
            <div className="social-link">
              <a href="https://twitter.com/SUP_Ecoapp" target="_blank" rel="noreferrer">
                <img src={twitterImage} alt="Twitter" />
              </a>
            </div>
            <div className="social-link">
              <a href="https://www.instagram.com/sup_ecoapp" target="_blank" rel="noreferrer">
                <img src={instagramImage} alt="Instagram" />
              </a>
            </div>
          </div>
        </div>
        <div className="bottom-row">
          <div className="l-side">
            <p className="copyright-heading">
              © 2019-2022 Krishpa Consultants
            </p>
          </div>
          <div className="r-side">
            <Link to="/terms-conditions">
              Terms
            </Link>
            <Link to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default AppFooter;
