import React, {useState, useEffect} from 'react';
import AppLinks from './app-links';
import {AppLogo} from '../../components'
import menuIcon from '../../assets/menu.png'
import './header.scss'

const AppHeader = ({showLogoProp}) => {
  const [showLogo, setShowLogo] = useState(!showLogoProp)
  const [isMobile, setIsMobile] = useState(false)
  const [showAppLinks, setShowAppLinks] = useState(false)
  useEffect(() => {
    if (showLogoProp) {
      document.addEventListener('scroll', () => {
        if (window.pageYOffset > 140 && !showLogo) {
          setShowLogo(true)
        } else if (window.pageYOffset < 140 && showLogo) {
          setShowLogo(false)
        }
      })
  
      return () => {
        document.removeEventListener('scroll', () => {
        })
      }
    }
  }, [showLogo])

  useEffect(() => {
    const isMobileFlag = window.innerWidth <= 767
    setIsMobile(isMobileFlag)
  }, [])

  const toggleShowMenu = () => {
    setShowAppLinks(!showAppLinks)
  }

  return (
    <header className={`app-header-container${showLogo ? ' fixed-navbar' : ''}`} style={{position: showLogoProp ? 'fixed' : 'relative'}}>
      <div className="content-section">
        <div className="app-logo-section">
          {showLogo && <AppLogo width={60} />}
        </div>
        {isMobile && (
          <div className="menu-section" onClick={toggleShowMenu}>
            <img src={menuIcon} alt="Menu Icon" />
          </div>
        )}
        {(showAppLinks || !isMobile) && (<AppLinks />)}
      </div>
    </header>
  )
}

AppHeader.defaultProps = {
  showLogoProp: true
}

export default AppHeader;