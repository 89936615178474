import React from 'react'
import {AppLogo} from '../../components'
import AppLandingBG from '../../assets/landing-bg.png'
import featuresImage from '../../assets/features.png'
import playStoreImage from '../../assets/google-play.png'
import appStoreImage from '../../assets/appstore.png'
import appLogoImage from '../../assets/sup-logo.png'
import './landing.scss'

const bgStyle = {
  backgroundImage: `url(${AppLandingBG})`,
  backgroundSize: 'cover',
  backgroundPosition: '0 0',
}

const AppLanding = () => {
  return (
    <section className="app-landing-section">
      <div className="content-section">
        <div className="al-left-side">
          <img src={appLogoImage} className='landing-app-logo' alt="SUP" />
          <p className='app-tagline'>Do good and Earn</p>
          <p className="app-main-desc">
            In solidarity with millions of Indian Citizens who want to take the Eco-friendly step forward.
          </p>
          <div className="app-store-links-section">
            <a href="https://play.google.com/store/apps/details?id=com.krishpa.sup" target="_blank" rel="noreferrer">
              <img width="140" height="42" src={playStoreImage} alt="Google Play" loading="lazy" />
            </a>
            <a href="https://apps.apple.com/in/app/sustainable-upgrade-program/id1553494353" target="_blank" rel="noreferrer">
							<img width="126" height="42" src={appStoreImage} alt="App Store" loading="lazy" />
              </a>
          </div>
        </div>
        <div className="al-right-side">
          <img src={featuresImage} alt="SUP Features" />
        </div>
      </div>
      <div className="bottom-image" style={bgStyle} />
    </section>
  )
}

export default AppLanding;