import React from 'react';
import {NavLink, useLocation} from 'react-router-dom'

const AppLinks = () => {
  const { pathname } = useLocation();
  return (
    <nav className="app-nav">
      <ul className='app-nav-ul'>
        <li className='app-nav-ul-li'>
          <NavLink
            to="/"
            isActive={() => ['/'].includes(pathname)}
            activeClassName="active-link">Home</NavLink>
        </li>
        <li className='app-nav-ul-li'>
          <NavLink
            to="/features"
            isActive={() => ['/features'].includes(pathname)}
            activeClassName="active-link">Features</NavLink>
        </li>
        <li className='app-nav-ul-li'>
          <NavLink
            to="/about"
            isActive={() => ['/about'].includes(pathname)}
            activeClassName="active-link">About Us</NavLink>
        </li>
        <li className='app-nav-ul-li'>
          <NavLink
            to="/contact"
            isActive={() => ['/contact'].includes(pathname)}
            activeClassName="active-link">Contact Us</NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default AppLinks;