import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { AppFooter, AppHeader } from "../../components";
import actionCalculator from "../../assets/action-calculator.png";
import ecoCoins from "../../assets/eco-coins.png";
import urbanForest from "../../assets/urban-forest.png";
import badges from "../../assets/badges.png";
import "react-tabs/style/react-tabs.css";
import "./features-page.scss";

const FeaturesPage = () => {
  return (
    <div className="features-page">
      <AppHeader showLogoProp={false} />
      <div className="f-content-section">
        <Tabs>
          <TabList>
            <Tab>Impact Calculator</Tab>
            <Tab>Eco Coins</Tab>
            <Tab>Urban Forests</Tab>
            <Tab>Climate Action Badges</Tab>
          </TabList>
          <TabPanel>
            <div className="feature-box">
              <div className="fb-left-side">
                <img src={actionCalculator} alt="Action Calculator" />
              </div>
              <div className="fb-right-side">
                <div className="line-indicator-two" />
                <h2 className="fb-heading">Impact Calculator</h2>
                <p className="fb-desc">
                  The Impact Calculator on the SUP is your much-needed reality
                  check!! Keep a track of the carbon equivalent of your daily
                  non-eco-friendly ways of living life, and adopt an
                  earth-friendly lifestyle by attempting to practice
                  eco-friendly activities as listed by us daily. <br />
                  <br /> Experience a positive change with various carbon-saving
                  tactics, and learn in detail how your new lifestyle helps the
                  Earth become clean and green again!! Gain our exclusive SUP
                  currency, Eco coins for all the eco-friendly deeds you do
                  every day.
                </p>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="feature-box">
              <div className="fb-left-side">
                <img src={ecoCoins} alt="Eco Coins" />
              </div>
              <div className="fb-right-side">
                <div className="line-indicator-two" />
                <h2 className="fb-heading">Eco Coins</h2>
                <p className="fb-desc">
                  India’s Climate Action Bank Account
                  <br />
                  <br /> Open your own Eco-bank account. Collect our social
                  responsibility- currency, Eco coins by doing sustainable
                  activities. Donate the earned eco coins to create urban
                  forests and Enter the world of Exclusive privileges and
                  rewards.
                </p>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="feature-box">
              <div className="fb-left-side">
                <img src={urbanForest} alt="Urabn Forest" />
              </div>
              <div className="fb-right-side">
                <div className="line-indicator-two" />
                <h2 className="fb-heading">Urban Forests</h2>
                <p className="fb-desc">
                  Together, Let’s Create Lungs in the city. <br />
                  <br />
                  All the Eco coins donated by users are redirected as saplings
                  to create Urban forests in Hyderabad. In official partnership
                  with Haritha Haram, by the Government of Telangana, we are
                  creating forest patches In the heart of the city.
                </p>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="feature-box">
              <div className="fb-left-side">
                <img src={badges} alt="Badges" />
              </div>
              <div className="fb-right-side">
                <div className="line-indicator-two" />
                <h2 className="fb-heading">Climate Action Badges</h2>
                <p className="fb-desc">
                  Join your social responsibility Community <br />
                  <br />
                  After donating the eco coins for Urban Forest creation, now
                  you can Earn the social responsibility badges that you
                  deserve. The bigger the forest you created, the higher the
                  badge you earn. Each level of badge unlocks exclusive
                  privileges, and rewards for all your sustainable efforts.
                  <br />
                  <br />
                  Level up your activities and earn more privileges. Make a
                  difference in climate change by doing activities that reduce
                  greenhouse gases, save water, and save resources. Your good
                  deeds will never go unnoticed with our purpose-driven social
                  responsibility badges.
                </p>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <div className="f-desc-section">
        <div className="f-desc-content">
        <p className="main-heading">
          SUP stands in solidarity with the millions of Indian citizens who want
          to take the Eco-friendly step forward.
        </p>
        <p className="desc">
          Make a difference in climate change by doing activities that reduce
          greenhouse gases, save water, and save resources. Your good deeds will
          never go unnoticed with our purpose-driven social responsibility
          badges.
        </p>
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default FeaturesPage;
