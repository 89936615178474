import React from 'react'

const TestimonialCard = ({data}) => {
  const {subject, message, user} = data;
  const {name, designation} = user || {name: 'NA', designation: 'NA'}
  return (
    <div className="testimonial-card">
      <h2 className='tc-heading'>{subject}</h2>
      <p className='tc-desc'>{message}</p>
      <p className='tc-name'>{name}</p>
      <p className='tc-role'>{designation}</p>
    </div>
  )
}

export default TestimonialCard;
