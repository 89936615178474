import React, {useEffect} from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { HomePage, FeaturesPage, AboutUsPage, ContactPage, PrivacyPolicyPage, TermsPage } from './pages';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const AppRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/features" exact>
          <FeaturesPage />
        </Route>
        <Route path="/about" exact>
          <AboutUsPage />
        </Route>
        <Route path="/contact" exact>
          <ContactPage />
        </Route>
        <Route path="/terms-conditions" exact>
          <TermsPage />
        </Route>
        <Route path="/privacy-policy" exact>
          <PrivacyPolicyPage />
        </Route>
        <Route path="/" exact>
          <HomePage />
        </Route>
      </Switch>
    </Router>
  )
}

export default AppRouter;