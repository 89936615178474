import React from "react";
import { AppFooter, AppHeader } from "../../components";
import linkedinImage from "../../assets/linkedin.png";
import yash from "../../assets/yash.jpeg";
import shiva from "../../assets/shiva.png";
import santhosh from "../../assets/santhosh.jpeg";
import lucky from "../../assets/lucky.jpeg";
import nigath from "../../assets/nighat.png";
import jothiga from "../../assets/jothiga.jpeg";
import ashok from "../../assets/ashok.png";
import chintu from "../../assets/chintu.png";
import madanmohan from "../../assets/madan-mohan.jpeg";
import subbu from "../../assets/subbu.jpeg";
import monisha from "../../assets/monisha.jpeg";
import biplab from "../../assets/biplab.png";
import "./about-page.scss";

const teamMembers = [
  {
    name: "Yashwanth Modugula",
    role: "Founder",
    image: yash,
    linkedin: "https://linkedin.com/in/yash-reddy-a42525117",
  },
  {
    name: "Shiva Bora",
    role: "Co-Founder",
    image: shiva,
    linkedin: "https://www.linkedin.com/in/siva-sankar-bora-525a0316b",
  },
  {
    name: "Santhosh Korimi",
    role: "Frontend Ninjaneer",
    image: santhosh,
    linkedin: "https://www.linkedin.com/in/santhosh-korimi/",
  },
  {
    name: "Laxminarayana Dasari",
    role: "Tech Knight",
    image: lucky,
    linkedin: "https://www.linkedin.com/in/laxminarayana-dasari-2a4004119/",
  },
  {
    name: "Nighat",
    role: "Organisational Coach",
    image: nigath,
  },
  {
    name: "Jothiga",
    role: "SuP Club Sensei",
    image: jothiga,
    linkedin: "https://www.linkedin.com/in/jothiga-k-b59b22224/ ",
  },
  {
    name: "Ashok Kumar",
    role: "Developer",
    image: ashok,
    linkedin: "https://www.linkedin.com/in/ashok-kumar-2b5a02142",
  },
  {
    name: "Chintu Yadav Sara",
    role: "Developer",
    image: chintu,
    linkedin: "https://www.linkedin.com/in/chintuyadavsara/",
  },
];

const advisoryBoard = [
  {
    name: "B. Madan Mohan Reddy",
    role: "President- RADS",
    subrole: "EX- OSD to Central Defense Minister",
    image: madanmohan,
  },
  {
    name: "Subbu Parameswaran",
    role: "Co-Founder and CEO",
    subrole: " Learning Curve Foundation",
    image: subbu,
    linkedin: "https://www.linkedin.com/in/subbu-parameswaran/",
  },
  {
    name: "Monisha Varma",
    role: "Co-founder/ CEO at Rubaroo",
    image: monisha,
    linkedin: "https://www.linkedin.com/in/monishavema/",
  },
  {
    name: "Biplab Ketan Paul",
    role: "Founder - Bhungroo",
    subrole: "US Gold Star Scholar",
    image: biplab,
    linkedin: "https://www.linkedin.com/in/biplab-ketan-paul-06a6a47/",
  },
];

const AboutUsPage = () => {
  return (
    <div className="about-us-page">
      <AppHeader showLogoProp={false} />
      <div className="ab-content-section">
        <h4 className="heading">About Us</h4>
        <p className="main-heading">
          SUP stands in solidarity with the millions of Indian citizens who want
          to take the Eco-friendly step forward.
        </p>
        <p className="desc">
          Make a difference in climate change by doing activities that reduce
          greenhouse gases, save water, and save resources. Your good deeds will
          never go unnoticed with our purpose-driven social responsibility
          badges.
        </p>
        <p className="desc">
          SUP strives hard to eliminate and replace the dry and technical
          climate change communication that has been prevalent in our country to
          date. We bring you a fun and interactive way to explore more about
          sustainability and what you can do to be responsible, eco-friendly
          citizens of tomorrow. Let us be your FRIENDS IN NEED, A FRIEND INDEED
          on the path to a carbon-efficient, eco-conscious life!!
        </p>
      </div>
      <div className="team-section">
        <div className="t-content">
          <h3 className="heading">The Team</h3>
          <p className="desc">
            We innovation, excellence, passion to work as well.
          </p>
          <div className="team-members">
            {teamMembers.map((member) => {
              return (
                <div className="tm-card" key={member.name}>
                  <div className="image-section">
                    <img src={member.image} alt={member.name} />
                  </div>
                  <h4 className="title">{member.name}</h4>
                  <p className="role">{member.role}</p>
                  {member.linkedin && (
                    <div className="linked-in-section">
                      <a
                        href={member.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedinImage} alt="Linkedin" />
                      </a>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="team-section advisory-board">
        <div className="t-content">
          <h3 className="heading">The Advisory Board</h3>
          <div className="team-members">
            {advisoryBoard.map((member) => {
              return (
                <div className="tm-card" key={member.name}>
                  <div className="image-section">
                    <img src={member.image} alt={member.name} />
                  </div>
                  <h4 className="title">{member.name}</h4>
                  <p className="role">{member.role}</p>
                  <p className="role">{member.subrole}</p>
                  {member.linkedin && (
                    <div className="linked-in-section">
                      <a
                        href={member.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedinImage} alt="Linkedin" />
                      </a>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* <div className="faq-section">
        <div className="content">
          <p className="main-heading">Frequently Asked Questions</p>
          <div className="faq-flex">
            <div className="faq-card">
              <h3 className="faq-heading">What is dummy text?</h3>
              <p className="faq-desc">
                Our dear friend, unprecedented population growth has put undue
                pressure on limited non-renewable resources available to us.
              </p>
            </div>
            <div className="faq-card">
              <h3 className="faq-heading">What is dummy text?</h3>
              <p className="faq-desc">
                Our dear friend, unprecedented population growth has put undue
                pressure on limited non-renewable resources available to us.
              </p>
            </div>
            <div className="faq-card">
              <h3 className="faq-heading">What is dummy text?</h3>
              <p className="faq-desc">
                Our dear friend, unprecedented population growth has put undue
                pressure on limited non-renewable resources available to us.
              </p>
            </div>
            <div className="faq-card">
              <h3 className="faq-heading">What is dummy text?</h3>
              <p className="faq-desc">
                Our dear friend, unprecedented population growth has put undue
                pressure on limited non-renewable resources available to us.
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <AppFooter />
    </div>
  );
};

export default AboutUsPage;
