import React from "react";
import { AppFooter, AppHeader } from "../../components";

const PrivacyPolicy = () => {
  return (
    <div className="static-page-container">
      <AppHeader showLogoProp={false} />
      <div className="content-section">
        <h2 style={{ textAlign: 'center'}}>PRIVACY POLICY</h2>
        <p className="heading">Welcome to SUP!</p>
        <p>
          We hope the content and tools provided by the SUP app, website, and
          social media sites help make it easy for you to take climate action.
          The Application is a tool to understand an individual's carbon
          footprint. We seek to provide the best information regarding actions
          to address the climate emergency, and carbon emissions, and to
          increase your social impact.
        </p>
        <p>
          At SUP, we take the privacy of our users ("Users," "You," or "Your")
          very seriously. Please read this Privacy Policy carefully before using
          the Application. By accessing or using SUP, you consent to the terms
          and conditions of this Privacy Policy.
        </p>
        <p>
          The services offered on our mobile application, SUP (the "App") are
          owned and operated by Krishpa Consultants. Please read this Privacy
          Policy (this "Policy") and our Terms of Service carefully, as you are
          agreeing to be bound by both documents by using our Service.
        </p>
        <p>
          This Policy applies to all Users of the Service. If you do not agree
          with any part of this Policy, you must not use the App. Your continued
          use of the App will constitute your acceptance of this Policy. Any new
          features or tools which are added to the current Service shall also be
          subject to this Policy. You can review the most recent version of this
          Policy at any time here. We reserve the right to update, change or
          replace any part of this Policy by posting updates and/or changes. It
          is your responsibility to check the Service periodically for changes.
          Your continued use of the Service following the posting of any changes
          constitutes acceptance of those changes.
        </p>
        <p className="heading">2. Definitions</p>
        <p>
          Throughout this Policy, we may use certain words or phrases, and it is
          important that you understand the meaning of them. The list is not
          all-encompassing and no definition should be considered binding to the
          point that it renders this Policy nonsensical:
        </p>
        <p>
          * "SUP" refers to our Company, App, Service, or a combination of all
          or some of the preceding definitions, depending on the context of the
          word.
        </p>
        <p>* "Policy" means this Privacy Policy.</p>
        <p>
          * "Service" refers to the services that we provide through the App,
          including the App itself.
        </p>
        <p>* "App" refers to the SUP mobile application.</p>
        <p>* "User" refers to but is not limited to all users of the App.</p>
        <p>* "You" refers to you, the person who is governed by this Policy.</p>
        <p className="heading">3. Information Collected</p>
        <p>
          We may automatically collect the following types of information as you
          use SUP
        </p>
        <p>
          Usage Information: We collect certain information automatically
          through your use of our App, such as which features of our App you
          access, the frequency of access, updates made on our App, and what you
          click on while on our App.
        </p>
        <p className="heading">Location Information </p>
        <p>
          We may collect information about your actual location that may be
          determined from GPS and other sensors that may reveal information on
          nearby devices, Wi-Fi access points, and cell towers.
        </p>
        <p>
          Device Information: We may collect information about the device you
          are using, such as hardware model, operating system, application
          version number, browser, and IP addresses.
        </p>
        <p>
          Mobile Device Information: In addition to the Device Information
          listed above, when you access our App, we may also collect the unique
          device identifier assigned to that device, mobile carrier, operating
          system, and other device attributes. We may also access functions on
          your mobile device for the operation of our mobile application, which
          can include: telephone, camera, browser, social media apps, or GPS.
        </p>
        <p className="heading">Cookies and Other Tracking Technologies: </p>
        <p>
          "Cookies" are small text files that are stored within your computer"s
          or mobile device"s memory by a website through your browser. Cookies
          enable a website or mobile app such as SUP to keep track of your
          activities on that website or app and to improve your use of the
          website or app, such as by keeping track of your preferences. You can
          change your browser settings on your personal computer or mobile
          device so that cookies are not stored. If you reject cookies, you may
          still use our Site, but you may not have access to some of our Site"s
          functions and your ability to use some areas of our Site may be
          limited.
        </p>
        <p className="heading">How We Use Cookies</p>
        <p>
          We use cookies for a variety of reasons detailed below. Unfortunately,
          in most cases, there are no industry standard options for disabling
          cookies without completely disabling the functionality and features
          they add to this site. It is recommended that you leave on all cookies
          if you are not sure whether you need them or not in case they are used
          to provide a service that you use.
        </p>
        <p className="heading">Disabling Cookies</p>
        <p>
          You can prevent the setting of cookies by adjusting the settings on
          your browser (see your browser Help for how to do this). Be aware that
          disabling cookies will affect the functionality of this and many other
          websites that you visit. Disabling cookies will usually result in also
          disabling certain functionality and features of this site. Therefore
          it is recommended that you do not disable cookies.
        </p>
        <p className="heading">The Cookies We Set</p>
        <p>
          * Email newsletters-related cookies: This site offers newsletter or
          email subscription services and cookies may be used to remember if you
          are already registered and whether to show certain notifications which
          might only be valid to subscribe/unsubscribed users.
        </p>
        <p>
          * Surveys-related Cookies: From time to time we offer user surveys and
          questionnaires to provide you with interesting insights, and helpful
          tools, or to understand our user base more accurately. These surveys
          may use cookies to remember who has already taken part in a survey or
          to provide you with accurate results after you change pages.
        </p>
        <p>
          * Forms-related cookies: When you submit data through a form such as
          those found on contact pages or comment forms cookies may be set to
          remember your user details for future correspondence.{" "}
        </p>
        <p>Third-Party Cookies</p>
        <p>
          In some special cases, we also use cookies provided by trusted third
          parties. The following section details which third-party cookies you
          might encounter through this site.
        </p>
        <p>
          This site may use Google Analytics which is one of the most widespread
          and trusted analytics solutions on the web for helping us to
          understand how you use the site and ways that we can improve your
          experience. These cookies may track things such as how long you spend
          on the site and the pages that you visit so we can continue to produce
          engaging content. For more information on Google Analytics cookies,
          see the official Google Analytics page.
        </p>
        <p>
          We may also use other website or mobile tracking technologies to
          identify you and keep track of your preferences, prevent fraudulent
          activity or improve Site security, assess the performance of our Site,
          and deliver content to you based on your interests on our Site and
          third-party websites.
        </p>
        <p className="heading">Data usage: </p>
        <p>
          We may process personal information provided through the means
          outlined above for the following purposes:
        </p>
        <p>To provide you access to our application</p>
        <p>
          For safety and security. This includes preventing, detecting, and
          investigating, fraud, security breaches, potentially prohibited or
          illegal activities, and enforcing our Terms &amp; Conditions
        </p>
        <p>
          For research and development. We are always looking for ways to make
          our application smarter, faster, secure, and useful to you. This
          includes information about features you interact with on our
          application and actions you take on these features
        </p>
        <p>
          To communicate with you about our application. We use your contact
          information to resolve problems with the site, applications, or tools,
          or for other purposes authorized by law.
        </p>
        <p>
          With your consent: We use information about you where you have given
          us consent to do so for a specific purpose not listed above.
        </p>
        <p>
          Disclosure to Government: We reserve the right to monitor and
          disclose, without your consent, any communications or Private
          Information, whenever requested to do so by courts, government
          agencies, or by law enforcement authorities.
        </p>
        <p className="heading">Identifying Information:</p>
        <p>
          We collect certain personal information from you when you sign up for
          our Service that can be used to identify you, such as your name,
          address, e-mail address, other information that we deem relevant for
          the purpose of providing you with our Service, and any information
          that you provide to us voluntarily. The information we collect from
          you to the extent that it is private is disclosed only in accordance
          with our Terms of Service and/or this Privacy Policy.
        </p>
        <p className="heading">Non-Identifying Information:</p>
        <p>
          Whenever you use our Service, we may collect non-identifying
          information from you, such as your IP address, referring URL,
          operating system, cookie information, and Internet Service Provider.
          Without a subpoena, voluntary compliance on the part of your Internet
          Service Provider, or additional records from a third party, this
          information alone cannot usually be used to identify you.
        </p>
        <p className="heading">4. Use of Your Information</p>
        <p>We may use your information to:</p>
        <p>* Enhance or improve the User experience or our Service.</p>
        <p>* Send e-mails about our Service or respond to inquiries.</p>
        <p>
          * Send e-mails and updates about events, including newsletters and
          notifications of amendments to legal documents such as this Privacy
          Policy and our Terms of Service.
        </p>
        <p>
          * Perform any other function that we believe in good faith is
          necessary to protect the security or proper functioning of our
          Service.
        </p>
        <p className="heading">
          5. Accessing, Editing, and Removing Your Information
        </p>
        <p>
          Users may in some cases be able to review and edit the personal
          information they have provided to us by logging into their account and
          editing their account. Although most changes may occur immediately,
          information may still be stored in a web browser"s cache. We take no
          responsibility for stored information in your cache, or in other
          devices that may store information, and disclaim all liability for
          such. In addition, we may, from time to time, retain residual
          information about you in our backup and/or database.
        </p>
        <p className="heading">6. SUP"s Use of Your Device"s Sensors</p>
        <p>
          By using our Service and installing our App, you give the App
          permission to access your device"s sensors, including but not limited
          to its GPS locator, accelerometer, and gyroscope. SUP uses your
          phone"s existing hardware sensors to provide its Service. SUP also
          collects information about your device, including but not limited to
          the device"s platform, operating system, wireless carrier, and device
          ID. Users can delete any sensor data collected by SUP on request. The
          sensor data received by your device is not used by SUP in the manner
          described in Section 5, above.
        </p>
        <p className="heading">7. Third Party Access to Your Information</p>

        <p>
          At times, the App may allow you to connect to or communicate with
          other apps or third-party services ("Third-Party Apps"). Links to
          Third-Party Apps are offered strictly for informational and
          convenience purposes and should not be construed as referrals or
          endorsements by SUP of the Third-Party Apps. The Third-Party Apps are
          developed and maintained by the respective third parties and are
          governed by their own terms of service and privacy policies. You
          should read the privacy policies and Terms of Service agreements of
          all Third-Party Apps. SUP is not responsible for the content of
          Third-Party Apps, nor does SUP make any warranties or representations
          with regard to the content, accuracy, opinions, warranties, products
          or services, privacy or security, intellectual property compliance, or
          links of such Third-Party Apps.
        </p>

        <p className="heading">
          8. Release of Your Information for Legal Purposes
        </p>

        <p>
          At times, it may become necessary or desirable, for legal purposes, to
          release your information in response to a request from a government
          agency or a private litigant. You agree that we may disclose your
          information to a third party where we believe, in good faith, that it
          is desirable to do so for the purposes of a civil action, criminal
          investigation, or other legal matter. In the event that we receive a
          subpoena affecting your privacy, we may elect to notify you to give
          you an opportunity to file a motion to quash the subpoena, or we may
          attempt to quash it ourselves, but we are not obligated to do either.
          We may also proactively report you, and release your information to,
          third parties where we believe that it is prudent to do so for legal
          reasons, such as our belief that you have engaged in fraudulent
          activities. You release us from any damages that may arise from or
          relate to the release of your information to a request from law
          enforcement agencies or private litigants.
        </p>

        <p className="heading">
          9. Commercial and Non-Commercial Communications
        </p>

        <p>
          By providing information to the Service that forms the basis of
          communication with you, such as contact information, you waive all
          rights to file complaints concerning unsolicited email from SUP since,
          by providing such information, you agree to receive communication from
          us or anyone else covered under this Privacy Policy. However, you may
          unsubscribe from certain communications by notifying event that you no
          longer wish to receive solicitations or information and we will
          endeavor to remove you from the database.
        </p>

        <p className="heading">10. Security Measures</p>

        <p>
          We take certain measures to enhance the security of our Service,
          including using SSL certificates. However, we make no representations
          as to the security or privacy of your information. It is in our
          interest to keep our Service secure, but we recommend that you use
          anti-virus software, firewalls, and other precautions to protect
          yourself from security threats.
        </p>

        <p className="heading">12. International Users</p>

        <p>
          SUP is located in India and is subject to the applicable country. Your
          information may be transferred to - and maintained on - computers
          located outside of your state, province, country, or other
          governmental jurisdiction where the privacy laws may not be as
          protective as those in your jurisdiction. If you are located outside
          India and choose to provide information to us, SUP transfers Personal
          Information to India and processes it there. Your consent to this
          Privacy Policy followed by your submission of such information
          represents your agreement to that transfer.
        </p>
        <p className="heading">13. Amendments</p>
        <p>
          We may amend this Privacy Policy from time to time. When we amend this
          Privacy Policy, we will post the changes here. Your continued use of
          our Service shall constitute your acceptance of such changes.
        </p>
        <p className="heading">Contact Us</p>
        <p>
          If you have questions about the policies described in this statement,
          please email us directly at contactkrishpa@gmail.com through this
          means of contact you may review, update, correct or delete your
          personal information in accordance with your applicable right to
          access, rectifier, erasure, restriction of processing, data
          portability and withdrawal of consent.
        </p>
      </div>
      <AppFooter />
    </div>
  );
};

export default PrivacyPolicy;
