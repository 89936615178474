import React from 'react'
import {AppFooter, AppHeader} from '../../components'
import './contact-page.scss'

const ContactPage = () => {
  return (
    <div className="contact-us-page">
      <AppHeader showLogoProp={false} />
      <div className="ab-content-section">
        <h4 className="heading">
          Contact Us
        </h4>
        <p className="main-heading">
          Questions ? Doubts ? <br /> Do not hesitate to contact us.
        </p>
        <div className="form-section">
          <div className="form-input">
            <input type="text" placeholder='Your name' />
          </div>
          <div className="form-input">
            <input type="text" placeholder='Your email address' />
          </div>
          <div className="form-input">
            <textarea placeholder='Message'></textarea>
          </div>
          <button className='send-button'>
            Send
          </button>
        </div>
      </div>
      <AppFooter />
    </div>
  )
}

export default ContactPage;