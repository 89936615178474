import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import TestimonialCard from "./card";
import './testimonials.scss'

const AppTestimonials= () => {
  const [slidesCount, setSlidesCount] = useState(3)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
  };

  const data = [
    {
      subject: 'SUP, An amazing app',
      message: "Yeah, it's really amazing app I have ever seen . This is one of the best app for maintain eco system and this app remainds our responsibilities on eco system .It's related to our eco friendly system",
      user: {
        name: 'Vennela Venkatadri',
        designation: 'Eco conscious member'
      }
    },
    {
      subject: 'The best eco-friendly app',
      message: "The best eco-friendly app which encourage the users to be more Eco friendly and let us walk in a sustainable path and gives us eco coins per our minimum daily responsibilities so we can buy products through it from the app its the easy way to use",
      user: {
        name: 'Rohitreddy Sidhu',
        designation: 'Eco conscious member'
      }
    },
    {
      subject: 'Super cool app',
      message: "Super cool app. Excellent UI. Best place to find all the eco events happening across India All the best Team SUP 👏",
      user: {
        name: 'Srinadh Survi',
        designation: 'Eco conscious member'
      }
    }
  ]

  useEffect(() => {
    if (window.innerWidth < 767) {
      setSlidesCount(1)
    }
  }, [])
  settings.slidesToShow = slidesCount
  return (
    <section className='app-testimonials'>
      <div className="content-section">
        <h2 className="at-heading">
          Testimonials
        </h2>
        <Slider {...settings}>
          {data.map((t) => (
            <TestimonialCard data={t} />
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default AppTestimonials;