import React from 'react';
import {Link} from 'react-router-dom'
import appLogoImage from '../../assets/sup-logo.png'

const AppLogo = ({width}) => {
  return (
    <div className="app-logo-section">
      <Link to="/">
        <img src={appLogoImage} width={width} alt="Sustainable Upgrade Program" />
      </Link>
    </div>
  )
}

AppLogo.defaultProps = {
  width: 125
}

export default AppLogo;