import React from 'react';

import {
  AppFeatures,
  AppFooter,
  AppHeader,
  AppLanding,
  AppTestimonials,
} from '../../components';

const HomePage = () => {
  return (
    <>
      <AppHeader />
      <AppLanding />
      <div className="home-landing-section__container">
        <AppFeatures />
        <AppTestimonials />
      </div>
      <AppFooter />
    </>
  );
};

export default HomePage;
